import dayjs from 'dayjs';

const dateFilter = (dateString: string) => {
  return dateString ? dayjs(dateString).format('DD.MM.YYYY') : '-';
};

const dateTimeFilter = (dateString: string) => {
  return dateString ? dayjs(dateString).format('DD.MM.YYYY, HH:mm:ss') : '-';
};

const pascalCaseFilter = (str: string) => {
  return str ? str.charAt(0).toLocaleUpperCase() + str.substr(1).toLocaleLowerCase() : '';
};

const formatFileSizeFilter = (fileSize: number) => {
  if (fileSize >= 1000000) {
    return `${(Number(fileSize) / 1024 / 1000).toFixed(2)} MB`;
  } else {
    return `${(Number(fileSize) / 1000).toFixed(2)} KB`;
  }
};

const wrapTextFilter = (text: any) => {
  if (text.length > 100) {
    return text.slice(0, 40) + ' ... ' + text.slice(-40);
  } else {
    return text;
  }
};

//Display monetary amounts properly
const currencyFilter = (value: number | string) => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
};

export { dateFilter, dateTimeFilter, pascalCaseFilter, formatFileSizeFilter, wrapTextFilter, currencyFilter };
