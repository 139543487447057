import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#004a69',
        secondary: '#fdc200',
        accent: '#fdc200'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
