import store from '@/store';
import { i18n } from '@/plugins/i18n';
import dayjs from 'dayjs';
import { TranslateResult } from 'vue-i18n';
import { isArray } from 'lodash';

export const init = (Vue?: any) => {
  //Handles errors thrown in Vue components
  if (Vue) {
    Vue.config.errorHandler = (err: any) => handleError(err, err.message);
  }
  //Handles all plain javascript errors
  window.addEventListener('error', (event) => handleError(event, event.message));

  //Handles promise rejections (err.g. API unavailable)
  window.addEventListener('unhandledrejection', (event) => handleError(event.reason, event.reason?.message));
};

const matchLocaleString = (str: string) => {
  return /^([A-z0-9]+\.)+[A-z0-9]+$/.test(`${str}`);
};

function handleChunkError(payload: { text: string | TranslateResult; color: string }) {
  const COUNT_KEY = 'chunk-load-refresh';
  const TIMESTAMP_KEY = 'chunk-load-refresh-timestamp';
  let count = parseInt(localStorage.getItem(COUNT_KEY) || '0');
  const lastRefreshAgo = dayjs().diff(dayjs(localStorage.getItem(TIMESTAMP_KEY) || '2000-01-01'), 'minutes');
  payload.text = i18n.t('common.errors.chunkError');
  if (count >= 3 && lastRefreshAgo > 2) {
    count = 0;
  }
  if (count < 3 && navigator.onLine) {
    localStorage.setItem(COUNT_KEY, `${count + 1}`);
    localStorage.setItem(TIMESTAMP_KEY, dayjs().toISOString());
    window.location.reload();
  }
}

function handleError(error: any = {}, message = '') {
  const payload: any = {
    text: message,
    color: 'error'
  };
  if (
    `${error.message}`.startsWith('Avoided redundant navigation to current location') ||
    `${error.message}`.startsWith('Uncaught TypeError: Cannot read property')
  ) {
    return;
  } else if (`${error}`.startsWith('TypeError')) {
    payload.text = i18n.t('common.errors.unknownError');
    payload.code = '01001';
  } else if (error.apiError) {
    const splitMsg = `${error}`.split(' ');
    const model = splitMsg.length > 1 ? splitMsg[1] : '';
    if (isArray(error.apiError)) {
      for (const errItem of error.apiError) {
        handleError(new Error(errItem));
      }
      return;
    } else {
      switch (error.apiError) {
        case 'Not Found':
          payload.text = i18n.t('common.errors.notFound', {
            model: i18n.tc(`common.models.${model}`, 1)
          });
          break;
        case 'Bad Request':
          payload.text = i18n.t('common.errors.badRequest');
          // TODO: Define all error types
          break;
        default:
          if (matchLocaleString(error.message)) {
            payload.text = i18n.t(error.message);
          }
      }
    }
  } else if (`${error.message}`.match(/Redirected when going from "(.*)" to "(.*)" via a navigation guard./)) {
    payload.color = 'warning';
    payload.text = i18n.t('common.errors.automaticRedirect');
  } else if (`${error.message}`.match(/Loading chunk chunk.(.*) failed.(.*)/)) {
    handleChunkError(payload);
  } else if (matchLocaleString(error.message)) {
    payload.text = i18n.t(error.message);
  }
  store.dispatch('setNotification', payload);
  store.dispatch('setLoading', false);
}

export { handleError, matchLocaleString };
