import _store from '../store';
import {
  currencyFilter,
  dateFilter,
  dateTimeFilter,
  formatFileSizeFilter,
  pascalCaseFilter,
  wrapTextFilter
} from '@/utils/filters';
import { i18n } from '@/plugins/i18n';
import _router from '../router';

const Utils = {
  install(Vue: any) {
    Vue.prototype.$showMessage = (message: string) => {
      _store.dispatch('setNotification', { text: message, color: 'success' });
    };

    Vue.prototype.$showWarning = (message: string) => {
      _store.dispatch('setNotification', { text: message, color: 'warning' });
    };

    Vue.prototype.$showError = (error: any | string, internalCode?: string) => {
      _store.dispatch('setNotification', {
        text: enhanceErrorMessage(error),
        color: 'error',
        code: error?.internalCode || internalCode
      });
      if (error?.status === 401 || error?.status === 403) {
        _router.push('/login');
      }
    };

    Vue.prototype.$tf = (path: string, fallbackPath: string, values?: any[] | any) => {
      return i18n.te(path) ? i18n.t(path, values) : i18n.t(fallbackPath, values);
    };

    Vue.filter('date', dateFilter);

    Vue.filter('dateTime', dateTimeFilter);

    Vue.filter('pascalCase', pascalCaseFilter);

    Vue.filter('formatFileSize', formatFileSizeFilter);

    Vue.filter('wrapText', wrapTextFilter);

    //Display monetary amounts properly
    Vue.filter('currency', currencyFilter);

    Vue.filter('addressType', (value: string) => getAddressType(value));

    // Vue.filter('pretty', prettyFilter);

    function getAddressType(value: string) {
      switch (value) {
        case 'DEFAULT':
          return 'Standard';
        case 'BILLING':
          return 'Rechnungsadresse';
      }
      return 'Unbekannt';
    }

    function enhanceErrorMessage(err: any) {
      if (err && err.message) {
        return err.message;
      } else if (err) {
        return err;
      }
      return 'Ein Fehler ist aufgetreten';
    }
  },

  getItemsForEnum(enumeration: any, i18nRoot: string) {
    return Object.keys(enumeration)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({ value: enumeration[key], text: Utils.enumValueToString(key, i18nRoot) }));
  },

  enumValueToString(value: any, i18nRoot: string) {
    return i18n.t(`${i18nRoot}.${value}`);
  }
};

export default Utils;
