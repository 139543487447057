
































import Navbar from '../components/Navbar.vue';
import ApplicationStatus from '@/components/ApplicationStatus.vue';
import { Component, Vue } from 'vue-property-decorator';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, firebaseApp } from '@/plugins/firebase';

@Component({
  components: {
    ApplicationStatus,
    Navbar
  }
})
export default class App extends Vue {
  name = 'App';

  isAuthenticated = false;

  created() {
    onAuthStateChanged(auth, async (user) => {
      this.isAuthenticated = !!user;
    });
  }

  handleLogout() {
    const auth = getAuth(firebaseApp);
    signOut(auth)
      .then(() => {
        this.$router.push('/login');
      })
      .catch(() => {
        //
      });
  }
}
