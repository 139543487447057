const messages = {
  de: {
    common: {
      confirm: 'Ok',
      confirmAlt: 'Bestätigen',
      cancel: 'Abbrechen',
      error: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      create: 'Neu',
      edit: 'Bearbeiten',
      understood: 'Verstanden',
      save: 'Speichern',
      send: 'Senden',
      show: 'Anzeigen',
      showAll: 'Alle {item} anzeigen',
      delete: 'Löschen',
      search: 'Suchen',
      add: 'Hinzufügen',
      pleaseWait: 'Bitte warten...',
      upload: 'Upload',
      document: 'Dokumentenverwaltung',
      status: 'Status',
      back: 'Zurück',
      backToHome: 'Zurück zur Homepage',
      next: 'Weiter',
      dates: {
        previousDays: 'Letzten {days} Tage'
      },
      actions: {
        add: 'Hinzufügen',
        addModel: '{model} hinzufügen',
        create: 'Neu',
        edit: 'Bearbeiten',
        save: 'Speichern',
        delete: 'Löschen',
        deactivate: 'Deaktivieren',
        more: 'Mehr',
        less: 'Weniger'
      },
      models: {
        User: 'Benutzer | Benutzer'
      },
      user: 'Benutzer',
      crud: {
        created: 'Erfolgreich angelegt.',
        updated: 'Erfolgreich aktualisiert.',
        deleted: 'Erfolgreich gelöscht.'
      },
      errors: {
        errorWithCode: 'Bitte kontaktieren Sie Ihren Administrator mit dem Fehlercode: {code}',
        unknownError: 'Ein unbekannter Fehler ist aufgetreten.',
        notFound: '{model} wurde nicht gefunden.',
        badRequest: 'Die Anfrage war fehlerhaft.',
        forbidden: 'Sie haben keinen Zugriff auf die angeforderten {model}.',
        automaticRedirect: 'Sie wurden automatisch weitergeleitet.'
      }
    },
    import: {
      title: 'Datei-Importe:',
      noImports: 'Es wurden noch keine Importe durchgeführt.'
    },
    navbar: {
      home: 'Startseite',
      administration: 'Benutzerverwaltung',
      investments: 'Investitionen',
      imports: 'Datenimporte',
      userSettings: 'Benutzereinstellung'
    },
    userSettings: {
      email: 'E-Mail',
      factorTitle: 'Zwei-Faktor Einstellungen',
      oldPassword: 'Bisheriges Passwort',
      password: 'Neues Passwort',
      passwordRepeat: 'Neues Passwort wiederholen',
      passwordChanged: 'Das Passwort wurde geändert.',
      passwordNotChanged: 'Das Passwort wurde geändert.',
      changePassword: 'Passwort ändern',
      invalidPassword: 'Das neue Passwort entspricht nicht den Richtlinien. Versuchen Sie es noch einmal.',
      factorName: 'Beschreibung',
      phoneNumber: 'Telefonnummer',
      enrollmentTime: 'Aktiv seit',
      unenrollTitle: 'Zweiten Faktor entfernen',
      unenrollConfirmation: 'Sind Sie sicher, dass Sie diese Telefonnummer entfernen möchten?'
    },
    validation: {
      noRole: 'Bitte wählen Sie eine Rolle aus.',
      notEmpty: 'Dieses Feld darf nicht leer sein',
      notEmail: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
      notPhoneNumber: 'Sie müssen eine gültige Telefonnummer mit Ländervorwahl eingeben. Bsp: +49172334455667',
      securePassword:
        'Min. 12 Zeichen bestehend aus Klein- und Großbuchstaben, Ziffern und Sonderzeichen (!?,.:;$°<{}"\'>|§&=\\-/`´€()+~@_#%^*).',
      passwordsMustMatch: 'Die Passwörter müssen übereinstimmen.',
      mandatoryFieldMarker: '(*) markiert erforderliche Felder',
      orderItemPricePattern: 'Preis muss positiv sein, ganzzahlig oder mit maximal 5 Nachkommastellen.',
      positiveNumber: 'Bitte geben sie eine positive Zahl ein.',
      terminalId: 'Terminal-ID muss aus genau 8 Ziffern bestehen.',
      personNumberLength: 'Personennummer muss aus genau 10 Ziffern bestehen.',
      personNumberChecksum: 'Personennummer muss dem Prüfzifferverfahren genügen.',
      iban: 'Der Wert ist keine gültige IBAN.'
    },
    login: {
      title: 'Anmeldung',
      email: 'E-Mail',
      password: 'Passwort',
      submit: 'Anmelden',
      logout: 'Abmelden',
      confirmationCode: 'Bestätigungscode',
      confirmLogin: 'Anmeldung bestätigen',
      mfaSelectInfo: 'Bitte wählen Sie das Gerät aus, auf welchem Sie Ihren Bestätigungscode erhalten möchten.',
      confirmInfo: 'Geben Sie den Bestätigungscode ein, den Sie per SMS erhalten haben.',
      notSuccessful: 'Diese Benutzerdaten waren leider nicht korrekt. Bitte prüfen Sie Ihre Eingaben.',
      notActivated:
        'Dieses Konto wurde noch nicht aktiviert. Bitte befolgen Sie die Anweisungen in Ihrer Aktivierungs E-Mail.',
      verificationFailed: 'Die Bestätigung war leider nicht erfolgreich. Bitte versuchen Sie es noch einmal.',
      forgotPassword: 'Passwort vergessen?'
    },
    resetPassword: {
      title: 'Passwort vergessen',
      back: 'Zurück zum Login',
      button: 'E-Mail senden',
      message: 'Eine E-Mail zur Zurücksetzung Ihres Passwortes wurde erfolgreich zugeschickt.'
    },
    account: {
      passwordRules:
        'Das Passwort muss mindestens 12 Zeichen lang sein und Groß- und Kleinbuchstaben, Ziffern sowie Sonderzeichen (!?,.:;$°<{}"\'>|§&=\\-/`´€()+~@_#%^*) enthalten.'
    },
    tables: {
      investmentsTable: 'Anleihen',
      item: 'Eintrag | Einträge',
      perPage: 'Benutzer pro Seite'
    },
    administration: {
      title: 'Benutzerverwaltung',
      role: 'Zugewiesene Rolle',
      createUser: 'Neuen Benutzer hinzufügen',
      editUser: 'Benutzer bearbeiten',
      deleteUser: 'Benutzer löschen',
      enableUser: 'Benutzer aktivieren',
      disableUser: 'Benutzer deaktivieren',
      deleteUserConfirmation: 'Sind Sie sicher, dass Sie den Benutzer löschen möchten?',
      userCreated: 'Der Benutzer wurde erfolgreich angelegt.',
      userUpdated: 'Der Benutzer wurde erfolgreich aktualisiert.',
      userDeleted: 'Der Benutzer wurde gelöscht.',
      userEnabled: 'Benutzer wurde aktiviert',
      userDisabled: 'Benutzer wurde deaktiviert',
      noUsers: 'Es sind keine Benutzer verfügbar.',
      changeUserStatus: 'Kontostatus ändern',
      userChanged: 'Kontostatus erfolgreich geändert.',
      assignUser: 'Benutzer zuweisen',
      disableUserConfirmation:
        'Sind Sie sicher, dass Sie dieses Konto deaktivieren möchten? Dadurch kann der Benutzer nicht mehr auf das System zugreifen.',
      enableUserConfirmation: 'Sind Sie sicher, dass Sie dieses Benutzerkonto wieder aktivieren möchten?',
      password: 'Passwort',
      activationCode: 'Aktivierungscode',
      activationCodePlaceholder: '(z. B. GeheimerCode111#)',
      activationCodeHint:
        'Vergeben Sie bitte eigenständig einen Code und teilen Sie diesen dem neu angelegten Benutzer persönlich mit. Der Code wird für die Aktivierung des Kontos benötigt.',
      accountDisabled: 'Benutzerkonto deaktiviert',
      accountPending: 'Wartet auf Registrierung',
      allowedCustomers: 'Verfügbare Kunden',
      assignedCustomers: 'Zugeordnete Kunden',
      name: 'Name',
      customerSearchPlaceholder: 'Geben Sie den Namen des Kunden ein...',
      email: 'E-Mail Adresse',
      resendVerificationMailTooltip: 'Registrierungsmail erneut versenden',
      resendVerificationMailDialog: {
        title: 'Bitte geben Sie einen neuen Aktivierungscode an',
        success: 'Die Registrierungsmail wurde erneut versendet',
        failed: 'Die Registrierungsmail konnte nicht versendet werden'
      },
      userSearch: {
        displayName: 'Name',
        customerId: 'Kundennummer',
        email: 'E-Mail'
      }
    },
    firebase: {
      errorCodes: {
        auth: {
          'access-not-allowed': {
            message: 'Sie haben nicht die erforderlichen Berechtigungen.'
          }
        }
      }
    }
  }
};

export default messages;
