import Vue from 'vue';
import App from '../src/views/App.vue';
import router from './router';
import { auth } from './plugins/firebase';
import store from './store';
import vuetify from '././plugins/vuetify.js';

//Plugins and utilities
import { i18n } from './plugins/i18n';

// General utils for formatting and filtering
import Utils from '@/utils/utils';
import { init } from '@/utils/ErrorHandler';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

Vue.use(Utils);
init(Vue);
Vue.config.productionTip = false;
store.dispatch('initStore');

onAuthStateChanged(auth, () => {
  new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: (h) => h(App)
  }).$mount('#app');
});
