import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex';
import { find } from 'lodash';
import { EStorageKeys, ITutorial, RootState } from '@/store/types.ts';

Vue.use(Vuex);

export const actions: ActionTree<RootState, RootState> = {
  initStore: ({ commit }) => {
    commit('LOAD_PAGES');
    commit('LOAD_MAINTENANCE_SETTINGS');
    commit('LOAD_PAGES_TIMESTAMP');
    commit('LOAD_TUTORIALS');
  },
  generateCorrelationId: async ({ getters, commit }) => {
    await commit('SET_CORRELATION_ID', generateUUID());
    return await getters.correlationId;
  },
  setLoading: ({ commit }, payload) => {
    commit('SET_LOADING', payload);
  },
  setNotification: ({ commit }, payload) => {
    commit('ADD_NOTIFICATION', payload);
  },
  setTutorial: ({ commit }, tutorial: ITutorial) => {
    commit('SET_TUTORIAL', tutorial);
  }
};
export const mutations: MutationTree<RootState> = {
  SET_LOADING: (state, payload) => (state.loading = payload),
  ADD_NOTIFICATION: (state, payload) => {
    state.notifications.unshift(payload);
    setTimeout(() => {
      state.notifications.pop();
    }, 4000);
  },
  SET_CORRELATION_ID: (state, payload) => (state.correlationId = payload),
  SET_PAGES_TIMESTAMP_PUBLIC: (state, payload) => (state.pagesTimestamp.public = payload),
  SET_PAGES_TIMESTAMP_AUTHENTICATED: (state, payload) => (state.pagesTimestamp.authenticated = payload),
  SET_PAGES_TIMESTAMP_MAINTENANCE: (state, payload) => (state.pagesTimestamp.maintenance = payload),
  SET_PAGE_IMPRINT: (state, payload) => (state.pages.imprint = payload),
  SET_PAGE_PRIVACY_POLICY: (state, payload) => (state.pages.privacyPolicy = payload),
  SET_PAGE_TERMS_AND_CONDITIONS: (state, payload) => (state.pages.termsAndConditions = payload),
  SET_PAGE_CURRENT_ISSUES: (state, payload) => (state.pages.currentIssues = payload),
  LOAD_PAGES_TIMESTAMP: (state) => {
    const times = getData(EStorageKeys.PAGES_TIMESTAMP, null);
    if (times) {
      state.pagesTimestamp = times;
    }
  },
  LOAD_PAGES: (state) => {
    const pages = getData(EStorageKeys.STATIC_PAGES, null);
    if (pages) {
      state.pages = pages;
    }
  },
  STORE_PAGES: (state) => {
    storeData(EStorageKeys.STATIC_PAGES, state.pages);
  },
  STORE_PAGES_TIMESTAMP: (state) => {
    storeData(EStorageKeys.PAGES_TIMESTAMP, state.pagesTimestamp);
  },
  SET_TUTORIAL: (state, tutorial: ITutorial) => {
    const index = state.tutorials.findIndex((item) => {
      return item.key === tutorial.key;
    });
    if (index !== -1) {
      state.tutorials[index].isRead = tutorial.isRead;
    } else {
      state.tutorials.push(tutorial);
    }
    storeData(EStorageKeys.TUTORIALS, state.tutorials);
  },
  LOAD_TUTORIALS: (state) => {
    const tutorials = getData(EStorageKeys.TUTORIALS, null);
    if (tutorials) {
      state.tutorials = tutorials;
    }
  },
  LOAD_MAINTENANCE_SETTINGS: (state) => {
    const maintenanceSettings = getData(EStorageKeys.MAINTENANCE_SETTINGS, null);
    if (maintenanceSettings) {
      state.maintenanceSettings = maintenanceSettings;
    }
  },
  SET_MAINTENANCE_SETTINGS: (state, settings) => {
    state.maintenanceSettings = settings;
    storeData(EStorageKeys.MAINTENANCE_SETTINGS, settings);
  }
};
export const getters: GetterTree<RootState, RootState> = {
  loading: (state) => state.loading,
  correlationId: (state) => state.correlationId,
  notifications: (state) => state.notifications,
  pages: (state) => state.pages,
  maintenance: (state) => state.maintenanceSettings,
  pagesTimestamp: (state) => state.pagesTimestamp,
  tutorial: (state) => (key: string) => {
    return find(state.tutorials, { key: key }) || null;
  }
};

const store: StoreOptions<RootState> = {
  // strict: process.env.NODE_ENV !== 'production',

  state: {
    correlationId: '',
    notifications: [],
    loading: false,
    pages: {
      imprint: '',
      privacyPolicy: '',
      termsAndConditions: '',
      currentIssues: ''
    },
    tutorials: [],
    pagesTimestamp: {
      public: null,
      authenticated: null,
      maintenance: null
    },
    maintenanceSettings: {
      noticeActive: false,
      notice: '',
      content: '',
      maintenanceActive: false
    }
  },
  mutations: mutations,
  getters: getters,
  actions: actions
};

const storeData = (key: EStorageKeys, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getData = (key: EStorageKeys, fallback = null) => {
  const item = localStorage.getItem(key) || fallback;
  if (item) {
    return JSON.parse(item);
  }
  return item;
};

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default new Vuex.Store<RootState>(store);
