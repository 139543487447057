import { auth } from '@/plugins/firebase';
import { applyActionCode, checkActionCode, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { ERole } from 'src/domain/enums/Role';

export class FirebaseAuthService {
  private vueInstance: any;

  constructor(vueInstance: any) {
    this.vueInstance = vueInstance;
  }

  static get currentUser() {
    return auth.currentUser;
  }

  static async getCurrentUserRoles(): Promise<null | ERole> {
    const currentUser = this.currentUser;
    const token = (await currentUser?.getIdTokenResult()) || null;
    if (token) {
      return (token.claims.role as any) || null;
    }
    return null;
  }

  static async getCurrentUser() {
    const currentUser = this.currentUser;
    return currentUser;
  }

  static async getCurrentUserTokenResult() {
    const currentUser = this.currentUser;
    if (!currentUser) {
      return null;
    }
    return await currentUser.getIdTokenResult();
  }

  async applyActionCode(code: string) {
    try {
      return await applyActionCode(auth, code);
    } catch (err) {
      this.onError(err);
    }
  }

  async checkActionCode(code: string) {
    try {
      return await checkActionCode(auth, code);
    } catch (err) {
      this.onError(err);
    }
  }

  async sendPasswordResetEmail(email: string) {
    try {
      return await sendPasswordResetEmail(auth, email);
    } catch (err) {
      this.onError(err);
    }
  }

  async login(email: string, password: string, showError = true) {
    try {
      return await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      if (err.code === 'auth/multi-factor-auth-required' && err.resolver?.hints.length > 0) {
        this.onError(err, false);
      } else {
        this.onError(err, showError);
      }
    }
  }

  async signOut() {
    try {
      await auth.signOut();
    } catch (err) {
      this.onError(err);
    }
  }

  onError(err: any, showError = true, throwErr = true) {
    if (err.code && showError) {
      const code = err.code.replace('/', '.');
      this.vueInstance.$showError(this.vueInstance.$t(`firebase.errorCodes.${code}`));
      err.noMsg = true;
    }
    if (throwErr) {
      throw err;
    }
  }
}
