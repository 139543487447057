























import { Component, Vue } from 'vue-property-decorator';
@Component
export default class ApplicationStatus extends Vue {
  get allNotifications() {
    return this.$store.getters.notifications;
  }

  get loading() {
    return this.$store.getters.loading;
  }
}
