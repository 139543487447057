import { Route } from 'vue-router';

const ANY = ['ADMIN', 'EDITOR'];

const routes: () => any[] = () => {
  const config = [
    {
      name: 'Home',
      path: '/',
      displayName: 'Startseite',
      showInNavigation: ['main'],
      component: () => import('../views/Home.vue'),
      icon: 'mdi-home',
      meta: {
        allowedRoles: ANY
      }
    },
    {
      name: 'Login',
      path: '/login',
      component: () => import('../components/Login.vue')
    },
    {
      name: 'ResetPasswort',
      path: '/reset-password',
      component: () => import('../components/ResetPassword.vue')
    },
    {
      path: '/account',
      name: 'Administration',
      displayName: 'Benutzerverwaltung',
      icon: 'mdi-account-cog',
      showInNavigation: ['main'],
      component: () => import('../views/users/Administration.vue'),
      meta: {
        allowedRoles: ANY
      }
    },
    {
      path: '/investments',
      name: 'Companies',
      showInNavigation: ['main'],
      component: () => import('../views/CompanyTable.vue'),
      meta: {
        allowedRoles: ANY
      }
    },
    {
      path: '/imports',
      name: 'Imports',
      showInNavigation: ['main'],
      component: () => import('../views/Import.vue'),
      meta: {
        allowedRoles: ANY
      }
    },
    {
      path: '/settings',
      name: 'UserSettings',
      icon: 'mdi-account',
      showInNavigation: ['main'],
      component: () => import('../views/users/UserSettings.vue'),
      meta: {
        allowedRoles: ANY
      }
    },
    {
      path: '/users/details/:id?',
      name: 'User',
      displayName: 'Benutzerdaten',
      component: () => import('../views/users/UserDetails.vue'),
      props: (route: Route) => ({
        userId: route.params.id
      }),
      meta: {
        allowedRoles: ANY
      }
    }
  ];
  return config;
};

export { routes };
