import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, useDeviceLanguage } from 'firebase/auth';

const firebaseApp = initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG));
const db = getFirestore(firebaseApp);

const auth = getAuth(firebaseApp);

useDeviceLanguage(auth);

export { auth, firebaseApp, db };
