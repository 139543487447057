import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './navigation';
import { intersection } from 'lodash';
import { RouteConfig } from 'vue-router';
import { i18n } from '@/plugins/i18n';
import { ERole } from '@/domain/enums/Role';
import { FirebaseAuthService } from '@/services/firebase/FirebaseAuthService';
import { auth } from '@/plugins/firebase';
import { signInWithCustomToken } from 'firebase/auth';
const vue = new Vue();
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: routes().filter((route) => route.path)
});

router.beforeEach((to, from, next) => guardRoute(to, from, next));

export const guardRoute = async (to: any, from: any, next: any) => {
  const noAuthNeeded = to.matched.some((record: any) => record.meta.requireAuth === false);
  if (noAuthNeeded) {
    next();
  }
  const requiresRole = to.matched.some((record: any) => record.meta.allowedRoles);
  const userRoles = await FirebaseAuthService.getCurrentUserRoles();
  if (!userRoles && to.path !== '/login' && requiresRole) {
    next('/login');
  } else if (requiresRole && !(await hasAccess(userRoles, to.meta.allowedRoles)) && to.path !== '/') {
    next('/');
    if (vue.$showError) {
      vue.$showError(i18n.t('firebase.errorCodes.auth.access-not-allowed.message'));
    }
  } else {
    next();
  }
};

async function hasAccess(userRoles: any, requiredRoles: any) {
  try {
    const urlToken = new URLSearchParams(window.location.search).get('token');
    if (userRoles) {
      return intersection([userRoles], requiredRoles).length > 0;
    } else if (urlToken) {
      const tokenRes = (await signInWithCustomToken(auth, urlToken).then((result) =>
        result.user.getIdTokenResult()
      )) as any;
      return intersection([tokenRes.claims.roles], requiredRoles).length > 0;
    }
  } catch (err) {
    return false;
  }
  return false;
}

async function allowedNavRoutes() {
  const currentUserRole = (await FirebaseAuthService.getCurrentUserRoles()) as any;
  let _routes = [];
  if (currentUserRole) {
    _routes = [...routes()].filter(
      (route) =>
        route.showInNavigation && route.showInNavigation.includes('main') && hasAccessTo(route, currentUserRole)
    );
  }
  return _routes;
}

function hasAccessTo(route: RouteConfig, roles: ERole[]) {
  if (!route.meta || !roles) {
    return false;
  }
  return route.meta.requireAuth === false || intersection(route.meta.allowedRoles, [roles]).length > 0;
}

export { allowedNavRoutes };
export default router;
