import dayjs from 'dayjs';
import { IMaintenanceSettings } from '@/domain/models/IMaintenanceSettings';

export enum EStorageKeys {
  STATIC_PAGES = 'static_pages',
  PAGES_TIMESTAMP = 'authenticated_pages_timestamp',
  TUTORIALS = 'tutorials',
  MAINTENANCE_SETTINGS = 'maintenance_settings',
  MAINTENANCE_SETTINGS_TIMESTAMP = 'maintenance_settings_timestamp'
}

export interface ITutorial {
  isRead: boolean;
  key: string;
}

export interface IStaticPagesContent {
  imprint: string;
  privacyPolicy: string;
  termsAndConditions: string;
  currentIssues: string;
}

export interface IStaticPagesTimestamp {
  public: dayjs.Dayjs | null;
  authenticated: dayjs.Dayjs | null;
  maintenance: dayjs.Dayjs | null;
}

export interface INotification {
  color: 'info' | 'error' | 'warning' | 'success';
  text: string;
  show: boolean;
}

export interface RootState {
  correlationId: string;
  notifications: INotification[];
  loading: boolean;
  tutorials: ITutorial[];
  pages: IStaticPagesContent;
  pagesTimestamp: IStaticPagesTimestamp;
  maintenanceSettings: IMaintenanceSettings;
}
