<template>
  <v-navigation-drawer app expand-on-hover permanent left>
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-icon>
          <v-icon size="30">mdi-account-circle</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-subtitle>{{ displayName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item v-for="item in navItems" :key="item.title" @click="$router.push(item.route)" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { auth } from '@/plugins/firebase';
import { allowedNavRoutes } from '@/router';
import { onAuthStateChanged } from 'firebase/auth';

export default {
  data() {
    return {
      navItems: [],
      items: [
        { title: this.$t('navbar.home'), icon: 'mdi-home-city', route: '/' },
        {
          title: this.$t('navbar.administration'),
          icon: 'mdi-account-cog',
          route: '/account'
        },
        {
          title: this.$t('navbar.investments'),
          icon: 'mdi-account-group-outline',
          route: '/investments'
        },
        {
          title: this.$t('navbar.imports'),
          icon: 'mdi-text-box-check-outline',
          route: '/imports'
        },
        {
          title: this.$t('navbar.userSettings'),
          icon: 'mdi-account',
          route: '/settings'
        }
      ]
    };
  },
  created() {
    onAuthStateChanged(auth, async () => {
      this.navItems = (await allowedNavRoutes()).length ? this.items : [];
    });
  },
  computed: {
    displayName: function () {
      return auth.currentUser ? auth.currentUser.displayName : 'Nicht angemeldet';
    }
  }
};
</script>

<style scoped></style>
